import classnames from 'classnames';
import * as React from 'react';
import FunnelIcon from '../../static/icons/FunnelIcon';
import VerifiedIcon from '../../static/icons/VerifiedIcon';
import '../../styles/global.scss';
import {
  OPTIONAL_QUALIFIERS_FOR_URL_STORAGE_KEY,
  OPTIONAL_QUALIFIERS_STORAGE_KEY,
} from '../../utils/constants';
import Alert from '../Alert';
import BreadCrumb from '../BreadCrumb';
import FacetFilter from '../FacetFilter';
import { FacetFilterOnChange } from '../FacetFilter/models';
import { FitmentSelectorWrapperProps } from '../FitmentSelectorWrapper/models';
import LoadingIndicator from '../LoadingIndicator';
import { ProductListResponse } from '../ProductListWrapper/models';
import PartslogicProductListWrapper from '../PartslogicProductListWrapper';
import FacetFilterModal from './FacetFilterModal';
import FitmentSelectorModal from './FitmentSelectorModal';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './searchPage.module.scss';
import { SearchPageContext, SearchPageProvider } from './SearchPageProvider';
import {
  getInitialFitment,
  getInitialVq,
  getProductQuery,
  getSearchParams,
  isEqual,
  isFacetEqual,
  parseResponse,
  parseSelectedFacets,
  parseVehicleQualifierQuery,
  qualifierQueryToRealId,
  qualifierQueryToTitleCase,
  transformOptionalLabelsAndData,
  updateUrl,
  transformKeys,
  SearchParams,
  FACET_PREFIX,
} from './utils';
import { Product } from '../../models/search';

// Facet query string prefix to get facets from URL and prevent extra requests to load filtered products.
export const SHOW_FITMENT_MODAL = 'show-fitment-modal';

export type SelectedFitmentValues = Array<{
  key: string;
  value: number | string;
}>;

interface SearchPageProps {
  groupId?: FitmentSelectorWrapperProps['groupId'];
  renderNoExactPartMatchMessage?: JSX.Element | null;
  fitmentRuleId?: FitmentSelectorWrapperProps['fitmentRuleId'];
  /** If set to true, items will display the discouint percentage badge */
  showBadge?: boolean;
  /** If enabled, the add to cart button will be shown on the product item */
  enableAddToCartButton?: boolean;
  /** Callback that will receive the product as a parameter */
  onAddToCart?: (item: Product) => void;
  /** Text to display in the add to cart button */
  addToCartButtonText?: string;
  /** This function is passed the product data and returns a boolean.
   * When enableAddToCartButton is set to "true", if the function returns "true", the cart button will be displayed.
   */
  validateShowAddToCartButton?: (product: Product) => boolean;
}

/*
  This component was created to be used in a non React application,
  for that reason we use URLSearchParams instead of React Router and
  the component is not optimized for an SPA. If you need a
  component optimized for SPA, please create one using sunhammer-ui components.
*/
const InnerSearchPage = ({ groupId, showBadge, ...props }: SearchPageProps) => {
  const queryString = new URLSearchParams(window.location.search);

  const [searchParams, setSearchParams] = React.useState<SearchParams>(
    getSearchParams(queryString)
  );
  const [productData, setProductData] = React.useState<ProductListResponse>();
  const {
    clearFacetsOnSaveState,
    selectedFacetState,
    facetKeysState,
    optionalLabelsDataState,
    optionalLabelsState,
    qualifiersStatusState,
  } = React.useContext(SearchPageContext);
  const [facetKeys, setFacetKeys] = facetKeysState;
  const [selectedFacets, setSelectedFacets] = selectedFacetState;
  const [, setClearFacetsOnSave] = clearFacetsOnSaveState;
  const [, setQualifiersStatus] = qualifiersStatusState;
  const [, setOptionalLabels] = optionalLabelsState;
  const [, setOptionalLabelsData] = optionalLabelsDataState;
  const [optValuesState, setOptValuesState] = React.useState({});

  const [selectedFitmentStr, setSelectedFitmentStr] = React.useState(
    getInitialFitment(queryString)?.replace(/\|/g, ' ') || ''
  );
  const [selectedFitmentQuery, setSelectedFitmentQuery] = React.useState(
    getInitialFitment(queryString)
  );
  const [selectedVq, setSelectedVq] = React.useState(
    Object.values(getInitialVq())?.join(', ')
  );
  const [isFacetModalOpen, setIsFacetModalOpen] = React.useState(false);
  const [isFitmentModalOpen, setIsFitmentModalOpen] = React.useState(false);
  const [hasFitmentModalShown, setHasFitmentModalShown] = React.useState(false);
  const [disbaleAllHtmlEl, setDisableAllHtmlEl] = React.useState(false);
  const facetCounter = React.useRef<number>(0);
  const collapseOnShowFacetModal = React.useRef(false);
  const [productsQuery, setProductsQuery] = React.useState(
    getProductQuery(selectedFacets, selectedFitmentQuery, groupId, queryString)
  );
  const allowUpdateProductQuery = React.useRef<boolean>(false);
  const [latestSelectedFacet, setLatestSelectedFacet] = React.useState([]);
  const [, setfacetSelectEventType] = React.useState(false);
  const [currentlySelectedQualifiers, setCurrentlySelectedQualifiers] =
    React.useState([]);
  const [removedFacet, setRemovedFacet] = React.useState(String);

  React.useEffect(() => {
    const updateFitment = (e: CustomEvent) => {
      const newFitment = e.detail.fitment;
      setSelectedFitmentStr(Object.values(newFitment).join(' '));
      setSelectedFitmentQuery(Object.values(newFitment).join('|'));
    };
    window.addEventListener('PL_FITMENT_CHANGED', updateFitment);

    return () => {
      window.removeEventListener('PL_FITMENT_CHANGED', updateFitment);
    };
  }, []);

  React.useEffect(() => {
    facetCounter.current = Object.keys(selectedFacets).reduce(
      (acc, item) => acc + selectedFacets[item].length,
      0
    );
  }, [selectedFacets]);

  React.useEffect(() => {
    // We don't want to update the product query object on first render becasue was already
    // set on the useState function, so we wait for a change on selectedFacets or selectedFitmentQuery
    if (allowUpdateProductQuery.current) {
      setProductsQuery(
        getProductQuery(
          selectedFacets,
          selectedFitmentQuery,
          groupId,
          queryString
        )
      );
    }
    allowUpdateProductQuery.current = true;
  }, [selectedFacets, selectedFitmentQuery, optValuesState]);

  React.useEffect(() => {
    if (!groupId) {
      // Clear fitment query when no groupId value is not provided
      updateUrl('fitment');
    }
  }, [groupId]);

  React.useEffect(() => {
    window.onpopstate = () => {
      setSearchParams(
        getSearchParams(new URLSearchParams(window.location.search))
      );
    };
    window.onpageshow = () => {
      const presavedqualifiers = JSON.parse(
        localStorage.getItem(OPTIONAL_QUALIFIERS_STORAGE_KEY)
      );
      if (
        Array.isArray(presavedqualifiers) &&
        presavedqualifiers.length >= 1 &&
        typeof presavedqualifiers[0] === 'object' &&
        Object.keys(presavedqualifiers[0]).length === 0
      ) {
        localStorage.setItem('nextOptLabelIdx', '0');
      }
    };
  }, []);
  React.useEffect(() => {
    if (removedFacet) {
      const matchedObject = currentlySelectedQualifiers.find((obj) => {
        return obj.facet === removedFacet;
      });

      if (matchedObject) {
        const qualifiersSelected = matchedObject.qualifiersSelected;
        if (allowUpdateProductQuery.current) {
          const params = new URLSearchParams(window.location.search);

          // Remove key-value pairs from the URL
          Object.entries(qualifiersSelected).forEach(([key, _value]) => {
            params.delete(`vq[${key}]`);
          });

          // Get the updated URL
          const updatedURL = `${window.location.origin}${
            window.location.pathname
          }?${params.toString()}`;

          // Trigger the new URL
          window.history.replaceState(null, '', updatedURL);

          // set new state for the qualifier-facet relations removing the last unselected facets

          const newQualifierState = currentlySelectedQualifiers.filter(
            (newQualifierState) => {
              return newQualifierState.facet !== removedFacet;
            }
          );
          setCurrentlySelectedQualifiers(newQualifierState);

          const currentQualifiers = {};

          newQualifierState.forEach((q) => {
            Object.assign(currentQualifiers, q.qualifiersSelected);
          });

          localStorage.setItem(
            OPTIONAL_QUALIFIERS_FOR_URL_STORAGE_KEY,
            '[' + JSON.stringify(currentQualifiers) + ']'
          );
          const capitalizedQualifiers = transformKeys(currentQualifiers);
          localStorage.setItem(
            OPTIONAL_QUALIFIERS_STORAGE_KEY,
            '[' + JSON.stringify(capitalizedQualifiers) + ']'
          );

          const rawVq = parseVehicleQualifierQuery(
            new URLSearchParams(window.location.search)
          );
          setSelectedVq(Object.values(rawVq).join(', '));
        }
      }
    }
  }, [removedFacet, currentlySelectedQualifiers]);
  const updateFacets = (data: ProductListResponse) => {
    if (!data?.facets.length) {
      return;
    }
    const facetkeys = data?.facets.map((item) => item.name) || [];
    setFacetKeys(facetkeys);
    const newSelectedFecets = facetkeys.reduce((acc, facet) => {
      const values = queryString.getAll(`${FACET_PREFIX}${facet}`);
      if (values.length) {
        return {
          ...acc,
          [facet]: values,
        };
      }
      return acc;
    }, {});
    if (!isFacetEqual(selectedFacets, newSelectedFecets)) {
      setSelectedFacets(newSelectedFecets);
    }
  };

  const renderCounter = (renderZero = false) => {
    return facetCounter.current || renderZero ? (
      <span
        className={classnames(
          styles.facetCounter,
          'Pl-filter-result--button--counter'
        )}
      >
        {facetCounter.current}
      </span>
    ) : null;
  };

  const renderFacet = (isMobile = false) => {
    if (!productData?.facets.length) {
      return null;
    }
    const collapse = isMobile && collapseOnShowFacetModal.current;
    return (
      <div className={styles.facetContainer}>
        {groupId ? (
          <div
            className={classnames(styles.desktopFitmentButton, {
              [styles.hide]: selectedFitmentStr,
            })}
          >
            {renderFitmentButton()}
          </div>
        ) : null}
        {isMobile && disbaleAllHtmlEl ? (
          <LoadingIndicator type="linear" />
        ) : null}

        <FacetFilter
          className={classnames({ [styles.facetFilterDesktop]: !isMobile })}
          data={parseResponse(productData?.facets, collapse)}
          styled
          enableCollapse
          selectedValues={selectedFacets}
          onChange={(data) => {
            setDisableAllHtmlEl(true);
            collapseOnShowFacetModal.current = false;
            setSearchParams({ ...searchParams, page: 1 });
            const parsedFacets = parseSelectedFacets(
              data as FacetFilterOnChange
            );
            setSelectedFacets(parsedFacets);
            facetKeys.forEach((item) =>
              updateUrl<string>(
                `${FACET_PREFIX}${item}`,
                parsedFacets[item] || []
              )
            );
            if (productData.qualifiersStatus === 'enabled') {
              setHasFitmentModalShown(false);
              if (data.isChecked) {
                setfacetSelectEventType(true);
                setLatestSelectedFacet((oldState) => {
                  return [...oldState, data.newValue];
                });
              } else {
                setfacetSelectEventType(false);
                setLatestSelectedFacet((oldState) => {
                  return oldState.filter((state) => {
                    return state !== data.newValue;
                  });
                });
              }
              if (data.isChecked === false && data.newValue) {
                setRemovedFacet(() => {
                  return String(data.newValue);
                });
              } else {
                setRemovedFacet(() => {
                  return String('');
                });
              }
            }
          }}
        />
      </div>
    );
  };

  const showFacetModal = (show: boolean) => {
    collapseOnShowFacetModal.current = show;
    setIsFacetModalOpen(show);
  };

  const renderFitmentButton = () =>
    !selectedFitmentStr ? (
      <button
        className={styles.fitmentButton}
        onClick={() => setIsFitmentModalOpen(true)}
      >
        <VerifiedIcon />
        Select Fitment
      </button>
    ) : null;

  const renderMobileFilterButtons = () => {
    return (
      <div
        className={classnames(styles.mobileFilterButtonsContainer, {
          [styles.singleColumn]: selectedFitmentStr || !groupId,
        })}
      >
        <button
          className={classnames(
            styles.facetButton,
            'Pl-filter-results--button'
          )}
          onClick={() => showFacetModal(true)}
        >
          <FunnelIcon />
          Filter Results
          {renderCounter()}
        </button>
        {groupId ? renderFitmentButton() : null}
      </div>
    );
  };

  const renderPageLoadingIndicator = () =>
    !productData && searchParams.q ? (
      <div className={styles.pageLoadingIndicator}>
        <LoadingIndicator type="linear" />
      </div>
    ) : null;

  const hasResults = productData?.list.length;

  return (
    <div
      className={classnames(
        styles.root,
        { disableAll: disbaleAllHtmlEl },
        'Pl-SearchPage--container'
      )}
    >
      {selectedFitmentStr && groupId ? (
        <Alert
          className={styles.fitmentVerifier}
          buttonText="Change fitment"
          onClick={() => setIsFitmentModalOpen(true)}
          styled
          showIcon={false}
          text={
            <>
              <b>{selectedFitmentStr}</b>
              <span style={{ fontWeight: 100, marginLeft: '10px' }}>
                {selectedVq}
              </span>
            </>
          }
          title={null}
          type="success"
        />
      ) : null}

      <BreadCrumb
        styled
        filters={
          searchParams.q
            ? [
                {
                  color: '#f8f8f8',
                  id: 1,
                  label: `keyword: ${searchParams.q}`,
                },
              ]
            : undefined
        }
        paths={{
          list: [
            {
              label: 'Home',
              url: '/',
            },
            {
              label: 'Search',
            },
          ],
          separator: '/',
        }}
        onRemoveFilter={() => {
          const params = new URLSearchParams(window.location.search);
          params.delete('q');
          window.location.href = `${window.location.origin}${
            window.location.pathname
          }?${params.toString()}`;
        }}
      />
      {renderPageLoadingIndicator()}
      <div
        className={classnames(styles.dataContainer, {
          [styles.noResults]: !hasResults,
        })}
      >
        {productData && productData?.facets.length ? (
          <>
            {renderMobileFilterButtons()}
            {renderFacet()}
          </>
        ) : (
          // We need this empty div to keep the layout splitted into 2 sections with css grid

          <div />
        )}

        <div>
          <PartslogicProductListWrapper
            className={styles.productList}
            // eslint-disable-next-line complexity
            showBadge={showBadge}
            // eslint-disable-next-line complexity
            onDataReceived={(data) => {
              const {
                qualifiersStatus,
                vehicleQualifiers,
                clearFacetsOnFitmentSave,
              } = data;
              setClearFacetsOnSave(clearFacetsOnFitmentSave);
              setQualifiersStatus(qualifiersStatus);
              setDisableAllHtmlEl(false);
              setProductData(data);
              if (!hasFitmentModalShown && qualifiersStatus === 'enabled') {
                if (
                  queryString.get(SHOW_FITMENT_MODAL) ||
                  Object.keys(selectedFacets).length > 0
                ) {
                  if (vehicleQualifiers.length > 0) {
                    setIsFitmentModalOpen(true);
                    setHasFitmentModalShown(true);
                  }
                }
              }
              const [labels, optionalData] =
                transformOptionalLabelsAndData(vehicleQualifiers);

              const localStorageOptionalRealIdQualifiers =
                JSON.parse(
                  localStorage.getItem(
                    OPTIONAL_QUALIFIERS_FOR_URL_STORAGE_KEY
                  ) || '{}'
                )?.[0] || {};

              const localStorageOptionaQualifiers =
                JSON.parse(
                  localStorage.getItem(OPTIONAL_QUALIFIERS_STORAGE_KEY) || '{}'
                )?.[0] || {};

              /**
               * Vq parsed on url will be pre-selected on
               * fitment selector
               */
              const rawVq = parseVehicleQualifierQuery(
                new URLSearchParams(window.location.search)
              );
              const isPrevAqEqual = isEqual(
                qualifierQueryToTitleCase(rawVq),
                localStorageOptionaQualifiers
              );

              if (!isPrevAqEqual && Object.keys(rawVq).length > 0) {
                localStorage.setItem(
                  OPTIONAL_QUALIFIERS_STORAGE_KEY,
                  '[' +
                    JSON.stringify(qualifierQueryToTitleCase(rawVq, labels)) +
                    ']'
                );
                localStorage.setItem(
                  OPTIONAL_QUALIFIERS_FOR_URL_STORAGE_KEY,
                  '[' + JSON.stringify(qualifierQueryToRealId(rawVq)) + ']'
                );
              }
              /**
               * end
               */

              /** reupdate VQs displayed next to fitment on Alert bar  */
              if (!isPrevAqEqual) {
                setSelectedVq(Object.values(rawVq).join(', '));
              }

              Object.keys(localStorageOptionaQualifiers).forEach((v) => {
                if (!Object.keys(optionalData).includes(v)) {
                  optionalData[v] = [
                    {
                      id: localStorageOptionaQualifiers[v],
                      name: localStorageOptionaQualifiers[v],
                    },
                  ];

                  Object.keys(localStorageOptionaQualifiers).forEach(
                    (vv, i) => {
                      if (v === vv) {
                        labels.push({
                          id: vv,
                          name: vv,
                          real_id: Object.keys(
                            localStorageOptionalRealIdQualifiers
                          )[i],
                        });
                      }
                    }
                  );
                }
              });
              setOptionalLabels(labels);
              setOptionalLabelsData(optionalData);
              updateFacets(data);
            }}
            onPageChanged={(page) => {
              setSearchParams({ ...searchParams, page });
              setTimeout(() => {
                window.scrollTo({ behavior: 'smooth', top: 0 });
              }, 1);
              updateUrl<keyof SearchParams>('page', page);
            }}
            currentPage={searchParams.page}
            search={searchParams.q || ''}
            styled
            showControls={!!hasResults}
            renderNoResults={() => (
              <h4
                className={classnames(
                  styles.noResultMsg,
                  'Pl-SearchPage--no-results-msg'
                )}
              >
                No results for your current search. Try a new search or change
                your saved fitment.
              </h4>
            )}
            showPagination
            showLoadingIndicator={!!productData}
            query={productsQuery}
            itemsPerPage={searchParams.limit}
            onItemsPerPageChange={(value) => {
              setSearchParams({ ...searchParams, limit: value });
              updateUrl<keyof SearchParams>('limit', value);
            }}
            selectedSort={searchParams.sort}
            onSortChange={(value) => {
              setSearchParams({ ...searchParams, sort: value });
              updateUrl<keyof SearchParams>('sort', value);
            }}
            orientation={searchParams.orientation}
            onLayoutChange={(value) => {
              setSearchParams({ ...searchParams, orientation: value });
              updateUrl<keyof SearchParams>('orientation', value);
            }}
            renderNoExactPartMatchMessage={props.renderNoExactPartMatchMessage}
            enableAddToCartButton={props.enableAddToCartButton}
            onAddToCart={props.onAddToCart}
            addToCartButtonText={props.addToCartButtonText}
            validateShowAddToCartButton={props.validateShowAddToCartButton}
          />
        </div>
      </div>
      <FacetFilterModal
        classes={{ root: styles.modal }}
        isModalOpen={isFacetModalOpen}
        disbaleAllHtmlEl={disbaleAllHtmlEl}
        showModal={showFacetModal}
        renderCounter={renderCounter}
        renderFacet={renderFacet}
        setSelectedFacets={setSelectedFacets}
        totalResults={productData?.total || 0}
        facetKeys={facetKeys}
      />
      <FitmentSelectorModal
        classes={{ root: styles.modal }}
        isModalOpen={isFitmentModalOpen}
        showModal={setIsFitmentModalOpen}
        disbaleAllHtmlEl={disbaleAllHtmlEl}
        onSubmit={(data, changed, optValues) => {
          if (Object.keys(optValues).length !== 0) {
            const lastFacet =
              latestSelectedFacet[latestSelectedFacet.length - 1];

            // eslint-disable-next-line complexity
            setCurrentlySelectedQualifiers((oldState) => {
              const updatedState = [...oldState];
              const existingIndex = updatedState.findIndex(
                (obj) => obj.facet === lastFacet
              );

              if (existingIndex !== -1) {
                const existingObject = updatedState[existingIndex];
                const qualifiersSelected = existingObject.qualifiersSelected;

                // Iterate over each key-value pair in optValues
                for (const key in optValues) {
                  let isUnique = true;

                  // Check if the key-value pair exists in any other object
                  for (const obj of updatedState) {
                    if (
                      obj.facet !== lastFacet &&
                      // eslint-disable-next-line no-prototype-builtins
                      obj.qualifiersSelected.hasOwnProperty(key) &&
                      obj.qualifiersSelected[key] === optValues[key]
                    ) {
                      isUnique = false;
                      break;
                    }
                  }

                  if (isUnique) {
                    qualifiersSelected[key] = optValues[key];
                  }
                }

                // Remove key-value pairs from qualifiersSelected that exist in other objects
                for (const key in qualifiersSelected) {
                  let isPresent = false;

                  for (const obj of updatedState) {
                    if (
                      obj.facet !== lastFacet &&
                      // eslint-disable-next-line no-prototype-builtins
                      obj.qualifiersSelected.hasOwnProperty(key) &&
                      obj.qualifiersSelected[key] === qualifiersSelected[key]
                    ) {
                      isPresent = true;
                      break;
                    }
                  }

                  if (isPresent) {
                    delete qualifiersSelected[key];
                  }
                }
              } else {
                const updatedOptValues = {};
                for (const key in optValues) {
                  let isUnique = true;
                  for (const obj of oldState) {
                    if (
                      obj.facet !== lastFacet &&
                      // eslint-disable-next-line no-prototype-builtins
                      obj.qualifiersSelected.hasOwnProperty(key) &&
                      obj.qualifiersSelected[key] === optValues[key]
                    ) {
                      isUnique = false;
                      break;
                    }
                  }
                  if (isUnique) {
                    updatedOptValues[key] = optValues[key];
                  }
                }

                updatedState.push({
                  facet: lastFacet || {},
                  qualifiersSelected: updatedOptValues,
                });
              }

              return updatedState;
            });
          }
          const queryString = new URLSearchParams(window.location.search);
          setSelectedFitmentStr(
            data.reduce((acc, item) => `${acc} ${item.value}`, '')
          );
          setfacetSelectEventType(false);
          // When fitment changes the value we disable HTML elements to prevent clicks
          // and then onDataReceived callback in PartslogicProductListWrapper component will enable
          // again all elements setting the value disableAllHtmlEl to FALSE.
          // We don't want to disbale HTML elements if there is no change in the fitment.
          setDisableAllHtmlEl(changed);
          setSearchParams({ ...searchParams, page: 1 });
          const fitmentQuery = data.map((item) => item.value).join('|');
          setSelectedFitmentQuery(fitmentQuery);
          setOptValuesState(optValues);
          setSelectedVq(Object.values(optValues).join(', '));
          updateUrl<keyof SearchParams>('fitment', fitmentQuery);

          if (Object.keys(optValues).length <= 0) {
            Object.keys(parseVehicleQualifierQuery(queryString)).forEach(
              (vqKeys) => {
                updateUrl<string>(vqKeys, '');
              }
            );
          } else {
            Object.keys(parseVehicleQualifierQuery(queryString)).forEach(
              (vqKeys) => {
                updateUrl<string>(vqKeys, '');
              }
            );
            // populates url with vq[:propertyId:]=':value:'
            Object.keys(optValues).forEach((vqKeys) => {
              updateUrl<string>(`vq[${vqKeys}]`, optValues[vqKeys]);
            });
          }
        }}
        groupId={groupId}
        fitmentRuleId={props.fitmentRuleId}
        selectedFitment={selectedFitmentQuery}
      />
    </div>
  );
};

const SearchPage = ({
  groupId,
  fitmentRuleId,
  showBadge = true,
  enableAddToCartButton,
  onAddToCart,
  addToCartButtonText,
  validateShowAddToCartButton,
}: SearchPageProps) => {
  return (
    <SearchPageProvider>
      <InnerSearchPage
        showBadge={showBadge}
        groupId={groupId}
        fitmentRuleId={fitmentRuleId}
        enableAddToCartButton={enableAddToCartButton}
        onAddToCart={onAddToCart}
        addToCartButtonText={addToCartButtonText}
        validateShowAddToCartButton={validateShowAddToCartButton}
      />
    </SearchPageProvider>
  );
};

export default SearchPage;
